import { Component } from '@angular/core';
import { UserRoutingEnum, CoreRoutingEnum, InternalBoardRoutingEnum, CandidateLayoutRoutingEnum, JobRequisitionRoutingEnum, JobCandidateRoutingEnum, SavedJobCandidateRoutingEnum, AppliedJobCandidateRoutingEnum } from '../../../core/enums/routing-enums.enum';
import { Router } from '@angular/router';
import { ApiService } from '../../../core/helpers/api.service';
import { LocalStorageService } from '../../../core/helpers/local-storage.service';
import { environment } from '../../../../environments/environment';
import { AuthGuard } from '../../../auth.guard';
import { LanguageService } from '../../../core/helpers/language.service';
@Component({
  selector: 'app-internal-header',
  templateUrl: './internal-header.component.html',
  styleUrl: './internal-header.component.scss'
})
export class InternalHeaderComponent {

  accountType = "";
  profileImage = "";
  userName = "";

  constructor(
    private router: Router,
    private apiService: ApiService,
    private localStorageService: LocalStorageService,
    private languageServices: LanguageService,
    private authGuard: AuthGuard
  ) {
    this.getAccountType();
  }

  onLogout() {
    this.apiService.logout();
    this.router.navigateByUrl(`/`);
  }

  getAccountType() {
    const user = this.localStorageService.getItem('user')
    this.accountType = user?.accountType || ''
    this.userName = user?.accountType === "Employer" ?
    user?.organizationName :
    user?.firstName + " " + user?.lastName || ''
    this.profileImage = user?.accountType === "Employer" ? user?.organizationLogo : user?.photo
  }

  getUserName() {
    const user = this.localStorageService.getItem('user')
    return user?.accountType === "Employer" ?
    user?.organizationName :
    user?.firstName + " " + user?.lastName || ''
  }

  hasProfilePic() {
    const user = this.localStorageService.getItem('user')
    return user?.accountType === "Employer" ? user?.organizationLogo : user?.photo
  }

  getProfilePic() {
    const user = this.localStorageService.getItem('user')
    return `${environment.assetsUrl}${user?.accountType === "Employer" ? user?.organizationLogo : user?.photo}`
  }

  canShowDropDown() {
    return !this.authGuard.waitingListStatus
  }

  getLanguage() {
    return this.languageServices.getLanguage() === "en" ? "عربي" : "English"
  }

  onSelectingTab(activeTab) {
    const user = this.localStorageService.getItem('user')
    switch (activeTab) {
      case 1:
        this.router.navigateByUrl(user?.accountType === "Employer" ? `/${CoreRoutingEnum.INTERNAL_BOARD}/${InternalBoardRoutingEnum.USER}/${UserRoutingEnum.PROFILE}` : `/${CoreRoutingEnum.CANDIDATE_LAYOUT}/${CandidateLayoutRoutingEnum.PROFILE}`);
        break;
      case 2:
        this.router.navigateByUrl(user?.accountType === "Employer" ? `/${CoreRoutingEnum.INTERNAL_BOARD}/${InternalBoardRoutingEnum.JOB_REQUISITION}/${JobRequisitionRoutingEnum.JOB_POST_LIST}` : `/${CoreRoutingEnum.CANDIDATE_LAYOUT}/${CandidateLayoutRoutingEnum.APPLIED}/${AppliedJobCandidateRoutingEnum.LIST}`);
        break;
      case 3:
        this.router.navigateByUrl(`/${CoreRoutingEnum.CANDIDATE_LAYOUT}/${CandidateLayoutRoutingEnum.SAVED}/${SavedJobCandidateRoutingEnum.LIST}`);
        break;
      case 4:
        this.router.navigateByUrl(user?.accountType === "Employer" || user?.accountType === "Org-User" ? `/${CoreRoutingEnum.INTERNAL_BOARD}/${InternalBoardRoutingEnum.CALENDAR}` : `/${CoreRoutingEnum.CANDIDATE_LAYOUT}/${CandidateLayoutRoutingEnum.CALENDAR}`);
        break;
      case 5:
        this.router.navigateByUrl(`/${CoreRoutingEnum.INTERNAL_BOARD}/${InternalBoardRoutingEnum.USER}/${UserRoutingEnum.PROFILE}`);
        break;
      case 6:
        this.router.navigateByUrl(user?.accountType === "Employer" || user?.accountType === "Org-User" ? `/${CoreRoutingEnum.INTERNAL_BOARD}/${InternalBoardRoutingEnum.CHANGE_PASSWORD}` : `/${CoreRoutingEnum.CANDIDATE_LAYOUT}/${CandidateLayoutRoutingEnum.CHANGE_PASSWORD}`);
        break;
      case 7:
        let currentLang = this.languageServices.getLanguage();
        this.languageServices.setLanguage(currentLang === "ar" ? "en" : "ar");
        break;
    }
  }
}
