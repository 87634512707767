import { Component } from '@angular/core';

@Component({
  selector: 'app-internal-footer',
  templateUrl: './internal-footer.component.html',
  styleUrl: './internal-footer.component.scss'
})
export class InternalFooterComponent {

}
